.Users {
  .verified-icon {
    padding-left: 1rem;
    &.verified {
      color: rgb(0, 128, 0);
    }
    &.unverified {
      color: #aaa;
    }
  }
}
