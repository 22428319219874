/*
b100 = #004570
b75 = #407494
b50 = #7fa2b7

g100 = #009341
g75 = #40ae71
g50 = #7fc9a0
*/

html,
body {
  height: 100%;
  width: 100%;
}
div#root { width:100%; }
.backButton {
  margin-bottom: 40px;
}

/* overrides */
.btn-primary {
  background-color: #407494;
  outline-color: #407494;
  border: 0;
}
.btn-primary:hover {
  background-color: #7fa2b7;
}
button.btn-upload {
  background: #eee;
  color: #4d7d9b;
}

/* General styles and defaults */
body {
  display: flex;
  background-color: #001520;
  font-family: "Open Sans", sans-serif;
}

.blurred-bg,
.archive-bg {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #888;
}

.login-logo, .corner-logo, .corner-logo-diamond { 
  background: transparent url(./assets/domino-logo-white.png) 0 0 no-repeat;
  background-size: contain;
}
.login-logo { width:346px; height:76px; display:inline-block;}

.corner-logo, .corner-logo-diamond {
  width: 218px;
  height: 48px;
  margin: 48px;
  display: inline-block;
}
.corner-logo-diamond { display: none; }

.flyin-action-overlay {
  width: 100%;
  height: 100%;
  visibility: hidden;
  background: rgba(0, 0, 0, 0.9);
  z-index: 1;
  position: absolute;
}

.flyin-action-area {
  z-index:101;
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 96px;
  padding: 32px;
  background: #004570;
  transition: width 0.2s ease 0s;
}
.flyin-active {
  width: 50%;
  min-width: 650px;
  z-index: 101;
  overflow-y: scroll;
  overflow-x: hidden;
}

.archive-content-container {
  padding: 160px 64px 64px 194px;
  width: 100%;
  height: 100%;
  position: absolute;
}
.archive-content-container.below {
  overflow: hidden;
}

/* **** Video details and player **** */

.category-display {
  display: inline-block;
  margin-right: 20px;
  font-size:.8rem;
}

.category-display a {
    color: #bbb;
    text-decoration: underline;
}
.category-display a:hover {
    color: #999;
}
span.category-display strong {
  text-transform: capitalize;
}

.video-details-overlay {
  width: 100%;
  height: 100%;
  position: fixed;
  background: rgba(0, 0, 0, 0.9);
  visibility: hidden;
  opacity: 0;
  transition: opacity 0.3s ease 0s;
  z-index: 102;
}

.video-details-container {
  width: 100%;
  position: fixed;
  bottom: -600px;
  visibility: hidden;
  transition: bottom 0.3s ease 0s;
  z-index: 102;
}
p.video-details-extras {
  display: block;
  max-width: 80%;
}
.category-delimiter {
  border:0;
  border-top:1px solid rgba(255,255,255,.3);
  width:100%;
  margin:1.5rem 0;
}
.show-details {
  opacity: 1;
  visibility: visible;
  bottom: 0px;
  max-height: 100%;
  overflow-y: scroll;
  overflow-x: hidden;
}
a.exit-details {
  position: fixed;
  top: 0;
  left: 0;
  color: #ccc !important;
  font-size: 48px;
  margin: 48px;
  cursor: pointer;
}

.video-player-container {
  background: #7fa2b7;
  min-width: 50%;
  max-width: 50%;
  padding: 32px 32px 8px;
  position: relative;
  bottom: -40px;
  left: 50%;
  transform:translateX(-50%);
  box-shadow: 0 0 32px rgba(0, 0, 0, 0.4);
}

video.video-player {
  width: 100%;
  position: relative;
  top: 0;
  left: 0;
}

.video-details-data {
  background: #407494;
  color: #eee;
  padding: 64px 0;
  margin:0 auto;
}

.video-details-data h1, .video-details-data p  {
  max-width: 80%;
  margin: 1rem auto 0;
}
.video-meta-actions-container {
  display: flex;
  justify-content: space-between;
  padding: 0.5rem 0 0;
}
.video-actions {
    display: flex;
    justify-content: flex-end;
}
.video-meta {
  margin: 5px 0 0;
  color: rgba(255,255,255,0.5);
  font-size: .8rem;
}


/* Headings and text formatting */
h1,
h2,
h3,
h4,
h5 {
  color: #eee;
  font-weight: 300;
}
.archive-content-container > h1 {
  margin-bottom: 64px;
}

div.favorite-videos h3::after {
  font-family: "Font Awesome 5 Free";
  font-size: 12px;
  font-weight: 900;
  content: "\f005";
  position: relative;
  top: -4px;
  margin-left: 12px;
  color: darkgoldenrod;
}

/* Search filter */
.search-filter {
  z-index: 2;
  display: inline-block;
  width: 400px;
  position: relative;
  margin-top: 139px;
  margin-left: 90px;
  opacity: 1;
}

.search-filter button.dropdown-toggle {
  min-width: 400px;
  background: #eee;
}

.search-filter input.multiselect-search {
  max-width: 300px;
  margin-right: 15px;
}

.search-form {
  margin: 0;
  padding: 0;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
}

input.search-input {
  height: 48px;
  width: 108px;
  padding: 20px 20px 20px 88px;
  border-radius: 32px;
  border: 0;
  outline: 0;
  background: rgba(155, 155, 155, 0.95);
  color: #fff;
  font-weight: 300;
  font-size: 1.25rem;
  line-height: 48px;
  transition: width 0.1s ease-in-out;
  margin-left: 8px;
}
input.search-input::placeholder {
  color:#ccc;
  font-size:1.1rem;
  font-style: italic;
}
/*
input.search-input:hover {
  width: 55%;
}
*/
input.search-input:focus, .search-input:not(:placeholder-shown) {
  width: 600px;
}



/* **** Upload and edit video form **** */
button.cancel-close-form {
  width: 48px;
  height: 48px;
  border-radius: 50%;
  border: 0;
  outline: 0;
  color: #fff;
  background: #7fc9a0;
  position: absolute;
  right: -24px;
}
.video-edit-upload-form {
  transition: opacity 0.2s ease 0.2s;
  color: #eee;
}
.video-form-inactive {
  opacity: 0;
  visibility: hidden;
}
.video-form-active {
  opacity: 1;
  visibility: visible;
}

.video-upload-control {
  width: 80%;
  margin: 32px auto;
  background: rgba(255, 255, 255, 0.3);
  padding: 20px;
  border: 2px dashed #eee;
  border-radius: 10px;
  text-align: center;
  cursor: pointer;
  display: flex;
  flex-flow: wrap;
  justify-content: center;
  outline:0;
}
.video-upload-control-active {
  border-color: rgb(33, 150, 243);
}
.video-upload-control-accepted {
  border-color: rgb(0, 230, 118);
}
.video-upload-control-rejected {
  border-color: rgb(255, 23, 68);
}
.video-upload-control h4 {
  font-size: 1rem;
}
.video-upload-control .alert-info {
  background: #ffffffc0;
  width:60%;
  min-width: 250px;
  font-size: 0.8rem;
}
.video-info-alert svg {
    margin: 0 1rem 0 0;
    align-self: center;
    font-size: 1.5rem;
}

.video-btn-delete {
  color: #a03030;
  text-decoration: underline;
  margin-left: 20px;
}
.video-btn-delete:hover {
  color: #f08080;
}
.video-btn-save {
  float: right;
}
button.video-btn-save:disabled {
  opacity: 0.2;
  background: #447;
  color: #77a;
}

/* Login screen specifics */
div.login {
  display: flex;
  height: 100vh;
}

.blurred-bg::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: url(./assets/login-bg.jpg) center center no-repeat;
  background-size: cover;
  filter: blur(10px);
  opacity: 0.3;
}

.login-logo {
  margin-bottom: 72px !important;
}

.login-form-container {
  height: 100%;
  width: auto;
  margin: 0 auto;
  background: #004570;
  padding: 1rem 2.5rem;
  z-index: 1;
  color: #fff;
}

.login a, .login .btn-link {
    color: #888;
    text-decoration: underline;
}

p.login-forgot-pw {
    margin-top:-20px;
    font-size:.8rem;
    text-align:center;
}
.login .btn-link { font-size:.8rem; }

.form-signin {
  width: 100%;
  max-width: 420px;
  padding: 15px;
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}

.btn-login {
    margin: 1.3rem 0 0;
}

@media (max-width: 576px) {
  .blurred-bg::before {
    background: #004570;
    opacity: 1;
  }

  .login-form-container { width:100%; padding:0; }
  .login-logo {
    width:80%;
    height:65px;
    margin:1rem 0 !important;
  }
  .form-signin {
    width:100%;
    top:0;
    transform: none;
    margin: 2rem auto 0;
  }

  .login h1 {
    font-size: 1.4rem;
  }
}

/* **** Modal dialog styling **** */
.modal-header h4.modal-title {
  color: #444;
}
.modal-footer a.btn-ok {
  color: #fff !important;
}

/* 
 ******
 Set up forms and inputs with placeholders to be floating 
 ****** 
*/
.form-label-group {
  position: relative;
  margin-bottom: 1rem;
}

.form-label-group > input,
.form-label-group > textarea,
.form-label-group > select {
  background: #eee;
  padding: 0.75rem;
}

.form-label-group > input,
.form-label-group > label {
  height: 3.125rem;
}
.form-label-group > textarea {
  max-height: 300px;
  min-height: 80px;
}
.form-label-group > select {
  min-height: 50px;
}

.form-label-group > label {
  padding: 0.75rem;
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  width: 100%;
  margin-bottom: 0; /* Override default <label> margin */
  line-height: 1.5;
  color: #495057;
  pointer-events: none;
  cursor: text; /* Match the input under the label */
  border: 1px solid transparent;
  border-radius: 0.25rem;
  transition: all 0.1s ease-in-out;
}

.form-label-group.category-selector > div:last-of-type {
  color: #222;
}

.form-label-group input::placeholder,
.form-label-group textarea::placeholder {
  color: transparent;
}

.form-label-group input:not(:placeholder-shown),
.form-label-group select:not(:placeholder-shown),
.form-label-group textarea:not(:placeholder-shown) {
  padding-top: 1.25rem;
  padding-bottom: 0.25rem;
}

.form-label-group input:not(:placeholder-shown) ~ label,
.form-label-group select:not(:placeholder-shown) ~ label,
.form-label-group textarea:not(:placeholder-shown) ~ label {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  font-size: 12px;
  color: #777;
}

/* **** Category and Keyword lists **** */
.video-meta-admin {
  margin: 20px;
  color: #444;
}
.dark {
  color: #444;
}
.tab-content {
  padding: 20px;
  background: #fff;
}
button.save-meta {
  float: right;
}
a.nav-link {
  color: #fff;
}
.list-group {
  background: #ccc;
}
.list-group-item {
  line-height: 20px;
}
.list-group-item:hover {
  background: #f2f2f2;
}
.list-group-item a.list-link {
  color: #222;
  display: inline-block;
  width: 90%;
  line-height: 40px;
}
.list-group-item button {
  float: right;
  height: 36px;
  width: 36px;
  font-size: 16px;
  padding: 0;
}

/*
  Administration of users and categories
*/
.administration-container.container {
  position: relative;
  margin-top: 2rem;
  padding: 0;
  width:90%;
}
div.Users.container table td {
    vertical-align: middle;
}
.Users.container div.btn-group {
  float: right;
  margin: 1rem;
}

@media (min-width: 576px) {
  .administration-container.container {
    width:90%;
  }
}
@media (min-width: 768px) {
  .administration-container.container {
    width:90%;
  }
}
@media (min-width: 992px) {
  .administration-container.container {
    width:90%;
  }
}
@media (min-width: 1200px) {
  .administration-container.container {
    width:90%;
  }
}


/*********** Pure dummy crap **************/
.active-container,
.active-header {
  display: block;
}

.inactive-container,
.inactive-header {
  display: none;
}

/******** Small screen layout adjustments ***********/
@media only screen and (max-width: 1024px) {
	.corner-logo { display:none; }
	.corner-logo-diamond {
	    position: absolute;
	    top: 0;
	    left: 6px;
	    clip: rect(0px, 51px, 40px, 0px);
	    padding: 0;
	    margin: 10px 0 0;
	    width: 180px;
	    display:block;
	 }
}
@media only screen and (max-width: 1023px) {
	.archive-content-container { padding-top:64px; padding-left:170px; }
	header { height:116px; }

	.archive-search-bar { width:auto; position:absolute; max-width: 95% !important;}
	input.search-input:focus, .search-input:not(:placeholder-shown) { width: 300px; } 
	input.search-input { font-size:20px!important; padding-left:60px; width:60px; }
	.archive-home.active-container { margin-left: -64px; }
	.flyin-action-area { 
    width: 64px;
    min-height: 570px;
    position:fixed;
  }
	.archive-search-bar { left:0 !important; }
	.archive-actions { left:0px!important; bottom:11px!important; }
	a[href="/administration"] { display: none; }
  .tooltip { display:none!important; }

  .flyin-active {
    min-width:100%;
    max-width:100%;
  }

  .video-edit-upload-form h1 {
      margin-top:1.7rem;
  }

	 header > h1 {
	    font-size: 24px;
	    top: 16px;
	    left: 72px;
	    text-align: left;
	}

	.search-filter {
		width:256px;
		margin-left:32px;
	}
	.close-player {
  		margin: 1rem;
	}
	.video-player-container {
	    max-width: 80%;
	    padding: 10px;
	}
	.video-details-data h1 {
	    font-size: 28px;
	}

}

/* Adjust button visibility for small screen height */
@media only screen and (max-height: 550px) {
  .sidebar button.action-cta.playlist, .sidebar button.action-cta.add { display:none; } 
}

.disabled-link {
  pointer-events: none;
}


