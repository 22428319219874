$theme-colors: ("sidebar-blue": #407494,
  "sidebar-green": #7fc9a0,
  "button-light-blue": #7fa2b7);

$tooltip-bg: #407494;

//colors
$dark-text: #222;
$thumb-bg: #ccc;
$container-bg: #fff;
$container-border: #000000e6;
$caption-bg: #004570;
$caption-fg: #fff;
$edit-button-bg: #004570a0;
$edit-button-bg-hover: #004570f0;
$edit-button-fg: #eee;
$new-fg: #f00;
$base_color: hsl(0, 0%, 50%);
$favourite-fg: rgb(206, 162, 17);
$main-bg: rgb(0, 21, 32);
$main-fg: rgb(103, 121, 133);


$bg-color: rgb(235, 236, 240);
$text-color: rgb(23, 43, 77);

@import "../node_modules/bootstrap/scss/bootstrap";
@import "~video-react/styles/scss/video-react";




//colors
$color_blue_charcoal_approx: #001520;

header.main {
  z-index: 100;
  width: 100%;
  height: 210px;
  position: fixed;
  top: 0;
  right: 0;
  padding: 0 0 0 192px;
  color: $main-fg;

  background: $main-bg;
  background: linear-gradient(to bottom, $main-bg 0, $main-bg 88%, fade-out($main-bg, 1) 100%);
}

.popup {
  min-width: 290px;
}

.popover-header {
  color: $dark-text;
}

div.sort-box svg {
  margin-right: 8px;
}


.sidebar {

  footer {
    position: absolute;
    bottom: 1em;
    left: 1em;
  }
  .archive-search-bar {
    position: fixed;
    top: 100px;
    left: 64px;
    width: auto;
    max-width: 80%;
  }

  .archive-actions {
    position: fixed;
    bottom: 64px;
    left: 64px;
  }

  button {
    &.action-cta {
      width: 48px;
      height: 48px;
      border: 0;
      outline: 0;
      border-radius: 50%;
      display: block;
      background: theme-color-level(button-light-blue);
      color: $white;
      margin: 15px 0 0;
      position: relative;
      transition: all 0.2s ease;

      &:hover {
        background: theme-color-level(sidebar-green);
        transform: rotate(360deg);
      }

    }

    &.search-cta {
      outline: 0;
      border: 0;
      border-radius: 50%;
      width: 48px;
      height: 48px;
      font-size: 20px;
      color: $white;
      background: theme-color-level(button-light-blue);
      position: relative;
      left: 8px;
      margin: 0;

      &:hover {
        background: theme-color-level(sidebar-green);
        transform: rotate(360deg);
      }
    }

    &.btn-lg {
      left: 8px;
      font-size: 16px;
    }

    &.btn-sm {
      width: 32px;
      height: 32px;
      left: 16px;
      font-size: 12px;
    }
  }
}

.close-player {
  position: fixed;
  top: 0;
  right: 0;
  color: $base_color;
  font-size: 2rem;
  margin: 2rem 3rem;
  cursor: pointer;

  &:hover {
    color: scale-color($base_color, $lightness: 50%);
  }
}


.video-category-container {
  margin: 48px 0 0;

  .video-list {
    list-style: none;
    list-style-type: none;
    margin: 1rem 0;
    padding: 0;
    display: inline-block;

    >div {
      display: inline-block;
      vertical-align: bottom;
    }
  }
}

.archive-playlist ul.video-list {
  margin-top: 4rem;
}

a.playlist-back-link {
  &:hover {
    text-decoration: none;
  }

  button.btn {
    color: #fff;
    text-decoration: none;
    display: block;

    position: relative;
    background: transparent;
    outline: 0;
    border: 0;
    transition: all .2s;

    &:active,
    &:hover,
    &:focus {
      background: transparent !important;
      outline: 0;
      border: 0;
      transform: scale(1.05);
    }
  }
}

.video-item {
  width: 216px;
  display: inline-block;
  margin: 0 12px 14px 0;

  .video-item-container {
    width: 100%;
    margin: 2px;
    position: relative;
    transition: all .3s ease 0s;
    display: block;

    .video-item-thumb {
      width: 216px;
      height: 144px;
      background: $thumb-bg;
      background-size: cover;
      background-position: center center;
      display: flex;
      align-items: center;
      justify-content: center;

      .loading {
        font-size: larger;
      }
    }

    .video-item-caption {
      width: 100%;
      color: $caption-fg;
      background: $caption-bg;
      padding: 10px;
      transition: all 0.3s ease 0s;
      min-height: 100px;

      >p {
        font-size: .75rem;
        margin: 0;
        display: none;
      }

      >h5 {
        font-size: 1.15rem !important;
        cursor: default;
        width: 90%;
        height: 70px;
        line-height: 1.4rem;
        display: flex;
      }

    }

    .video-item-favourite {
      top: .75rem;
      left: .75rem;
      position: absolute;
      background: transparent;
      cursor: pointer;
      color: rgba(255, 255, 255, 0.8);
      filter: drop-shadow(1px 1px 0px rgba(0, 0, 0, 0.6));
      transition: color .2s;

      &:hover {
        color: rgba(255, 255, 255, 1);
      }

      &.isFavourite {
        color: $favourite-fg;
      }
    }

    &.is-new {
      .video-item-favourite {
        left: 4.25rem;
      }
    }

    .video-item-new, .video-item-waiting-approval {
      position: absolute;
      top: .5rem;
      left: 0;
      color: $edit-button-fg;
      font-weight: 400;
      pointer-events: none;
      background: darkgreen;
      padding: .25rem 1rem;
      line-height: 1rem;
      font-size: .75rem;
    }
    .video-item-waiting-approval { background: orange; z-index:1; }

    button.video-item-edit {
      background: $edit-button-bg;
      width: 32px;
      height: 32px;
      border: 0;
      outline: 0;
      opacity: 0;
      position: absolute;
      top: 0;
      right: 0;
      color: $edit-button-fg;
      transition: all 0.3s ease 0s;
      cursor: pointer;

      &:hover {
        background: $edit-button-bg-hover;
      }

      a {
        color: $edit-button-fg;
      }
    }

    .video-item-ctx-button {
      bottom: 62px;
      right: 0;
      position: absolute;
      background: transparent;
      width: 32px;
      height: 32px;
      border: 0;
      outline: 0;
      color: $edit-button-fg;
      cursor: pointer;


      a {
        color: $edit-button-fg;
      }

    }

  }

  &:hover,
  &.forcedVisible {
    .video-item-container {
      border-color: $container-border;
      box-shadow: 0 0 20px $container-border;
      text-decoration: none;
      outline: medium none;
      transition: all .3s ease 0s;
      transform: scale(1.1);
      background-color: $container-bg;
      z-index: 1;

      .video-item-edit,
      .video-item-ctx-button {
        opacity: 1;
      }
    }

    .video-item-caption {
      background: $edit-button-bg;
    }
  }

  &.forcedVisible .video-item-container {
    z-index: 10;

  }


  &:not(.approved):not(:hover) .video-item-container a .video-item-thumb,
  &:not(.approved):not(:hover) .video-item-container button,
  &:not(.approved):not(:hover) .video-item-container .video-item-caption {
      filter: blur(1px);
  }

}

.video-item-ctx {
  width: 290px;

  .popover-body {
    width: 275px;

    .list-group {
      background: #fff;
    }

    .list-group-item,
    .list-group-item div.form-check,
    .list-group-item span {
      display: inline-flex;
    }
  }


}

.playlist-selector {
  background: #fff !important;

  .favourite-list-entry {
    color: $favourite-fg;
    padding: .75rem 1.25rem .75rem .2rem;
  }

  .playlist-link {
    color: $dark-text;
    padding-left: .5rem;
  }

  .playlist-edit {
    color: #888;
    position: absolute;
    right: 0;
    opacity: 0;
    transition: all .1s;
  }

  .list-group-item:hover {
    background: #fff !important;

    .playlist-edit {
      opacity: 1;
    }
  }
}

.playslist-no-lists-placeholder {
  padding: 1.25rem 1rem .75rem;
}

.video-playlist-selector {
  .favourite-list-entry {
    color: $favourite-fg;
  }

  .selected {
    font-weight: bold;
  }
}

.edit-playlist {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
}

.playlist-video-entry {
  cursor: pointer;

  &.row {
    padding: 1rem;
    border-top: 1px solid #eee;
    align-items: center;
    transition: all .15s;


    &:first-of-type {
      border: 0;
    }

    &:hover {
      background-color: rgba(0, 0, 0, .02);
    }

    a {
      color: $dark-text;
    }

    button.btn-link {
      color: #bbb;

      &:hover {
        color: #df5050;
      }
    }
  }

  &.row>div {
    display: flex;
    justify-content: space-between;
  }

  span {
    margin-left: 1rem;
  }
}

.playlist-video-thumb {
  flex-grow: 1;
  height: 80px;
  max-width: 130px;
}

.card-title.h5 {
  padding: 0.7rem 0;
  margin: 0;
}

.card-subtitle.h6 {
    font-size: 1.4rem;
    padding: 1rem 0 .5rem;
    margin:0;
}



.playlist-video-entry input.form-control.playlist-title-input {
  transition: all .2s;

  &:focus {
    transform: scale(1.02);
  }
}

button.btn-add-playlist {
  margin: .5rem 0 0;
  border-radius: 3px !important;
  color: #fff;
  text-align: center;
  background: #004085;
  width: 100%;
  display: inline-block !important;
  transition: all .2s;
}

.playlistVideo-enter {
  opacity: 0;
}

.playlistVideo-enter-active {
  opacity: 1;
  transition: opacity 500ms ease-in;
}

.playlistVideo-exit {
  opacity: 1;
}

.playlistVideo-exit-active {
  opacity: 0;
  transition: opacity 500ms ease-in;
}


div.select_group {
  display: flex;
  align-items: center;
  justify-content: space-between;

  span.badge {
    background-color: $bg-color;
    border-radius: 2em;
    color: $text-color;
    display: inline-block;
    font-size: 12;
    font-weight: normal;
    line-height: 1;
    min-width: 1;
    padding: 0.16666666666667em 0.5em;
    text-align: center;
  }
}

.sortable-header {
  cursor: pointer;
}

/* Mobile specific styles */
@media only screen and (max-width: 576px) {
  .playlist-video-entry.row {
    align-items: flex-start;
  }

  .playlist-video-actions {
    order: 2;
    flex-basis: 20%;
  }

  .playlist-video-title {
    order: 3;
    padding: 1rem 0;
  }

  .playlist-video-thumb {
    flex-grow: 1;
    height: 120px;
    flex-basis: 70%;
    max-width: 70%;
  }

  input.playlist-title-input {
    width: 95%;
  }

}

$share_box_fg: #444;
$share_box_border: #888;
$video_sharelink_fg: #eee;
$videolist_sharelink_fg: #444;
$share_url_bg: #eee;
$share_url_border: #ccc;

.link-share-box {
  h5 {
    color: $share_box_fg;
  }
  button.close-share-box {
    position: absolute;
    top: 4px;
    right: 6px;
    width: 12px;
    height: 12px;
    font-size: 12px;
    padding: 0;
    border: 0;
    background: transparent;
    outline: 0;
    color: $share_box_border;
  }
  button.btn-copy-url {
    background: transparent;
    color: $share_box_fg;
    border: 1px solid $share_box_border;
    outline: 0;
    opacity: 1;
    transition: opacity .2s;
    width: 32px;
    height: 32px;
    line-height: 10px;
    font-size: 10px;
    padding: 0;
    border-radius: 4px;
    order: 2;
    flex-basis: 32px;

    &:hover {
      opacity: .7;
    }
  }
}

.link-share-group {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  flex-direction: row;

  .share-url-field {
    height: 32px;
    font-size: 14px;
    white-space: nowrap;
    border: 1px solid $share_url_border;
    padding: 4px;
    line-height: 20px;
    overflow: hidden;
    order: 1;
    flex-basis: 85%;
    background: $share_url_bg;
  }
}

.sharelink {
  &-video {
    color: $video_sharelink_fg;
  }

  &-videolist {
    color: $videolist_sharelink_fg;
  }

  button {
    background: transparent;
    border: 0;
    outline: 0;
    opacity: .7;
    transition: opacity .2s;

    &:hover {
      opacity: 1;
    }
  }
}

footer.termsAndConditions {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 2000;
  min-width: 300px;
  display: flex;
  justify-content: center;
  height: 100%;
}

.termsAndConditions .form-group {
  margin: 1rem 1rem 2rem;
}

.terms-card {
  width: 30%;
  min-width: 300px;
  max-height: 450px;
  min-height: 100px;
  align-self: center;
  box-shadow: 0 0 39px rgba(255,255,255,0.5);
  border-radius: 10px;
  border:1px solid #ffffff80;
}
.termsAndConditions.fade:not(.show) {
    display: none !important;
}

label.public-ticker {
    color: #888;
}